<template>
  <ModalMain
    :id="modalId"
    :title="title"
    :ok-title="saveButtonTitle"
    :loading="isLoading"
    :delete-disabled="!formData"
    size="lg"
    @hidden="onHidden"
    @ok="onSave"
    @cancel="onCancel"
    @remove="$emit('onRemove', formData.id)"
  >
    <b-form @submit.prevent="onSave">
      <InputString
        class="name"
        :label="$t('label.name')"
        size="lg"
        v-model="$v.form.name.$model"
        :vuelidate="$v.form.name"
      />
      <InputString
        class="slug"
        :label="$t('label.slug')"
        size="lg"
        v-model="$v.form.slug.$model"
        :vuelidate="$v.form.slug"
      />
      <InputEditor
        class="description"
        :label="$t('label.description')"
        size="lg"
        v-model="$v.form.description.$model"
        :vuelidate="$v.form.description"
      />
    </b-form>
  </ModalMain>
</template>

<script>
import { isNil } from 'lodash';
import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { PAGE_CREATE, PAGE_UPDATE } from '@/store/actions/page';
import editModalMixin from '@/mixins/editModalMixin';
import ModalMain from '@/components/ui/ModalMain';
import InputString from '@/components/ui/input/InputString';
import InputEditor from '@/components/ui/input/InputEditor';

const defaultValuesForForm = () => ({
  name: '',
  slug: '',
  description: '',
});

export default {
  mixins: [validationMixin, editModalMixin],

  props: {
    formData: Object,
  },

  data() {
    return {
      form: defaultValuesForForm(),
    };
  },

  validations: {
    form: {
      name: {
        required,
      },
      slug: {
        required,
      },
      description: {
        required,
      },
    },
  },

  watch: {
    formData() {
      this.updateForm();
    },
  },

  computed: {
    ...mapGetters(['pageStatus']),
    isLoading() {
      return this.pageStatus === 'loading';
    },
    title() {
      return isNil(this.formData) ? this.$t('page.newPage') : this.$t('page.editPage');
    },
    saveButtonTitle() {
      return isNil(this.formData) ? this.$t('text.addButton') : this.$t('text.updateButton');
    },
  },

  methods: {
    updateForm() {
      this.form = this.formData
        ? {
            name: this.formData.name,
            slug: this.formData.slug,
            description: this.formData.description,
          }
        : defaultValuesForForm();
      this.$v.$reset();
    },
    resetForm() {
      this.form = defaultValuesForForm();
      this.$v.$reset();
    },
    onHidden() {
      this.$emit('onHidden');
      this.resetForm();
    },
    onCancel() {
      this.hideEditModal();
    },
    async onSave() {
      if (this.$v) {
        this.$v.form.$touch();
        if (this.$v.form.$anyError) {
          return;
        }
      }

      const data = this.form;

      if (isNil(this.formData)) {
        await this.$store.dispatch(PAGE_CREATE, data);
      } else {
        const payload = {
          id: this.formData.id,
          data,
        };
        await this.$store.dispatch(PAGE_UPDATE, payload);
      }
      if (this.pageStatus === 'success') {
        this.hideEditModal();
      }
    },
  },

  components: {
    ModalMain,
    InputString,
    InputEditor,
  },
};
</script>
