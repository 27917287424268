<template>
  <ModalMain
    :id="modalId"
    :title="title"
    :ok-title="saveButtonTitle"
    :loading="isLoading"
    delete-disabled
    @hidden="onHidden"
    @ok="onSave"
    @cancel="onCancel"
    @remove="$emit('onRemove', formData.id)"
  >
    <b-form @submit.prevent="onSave">
      <b-row class="mb-3">
        <b-col cols="6" sm="6">
          <InputLabel>{{ $t('label.avatar') }}</InputLabel>
          <InputPhoto
            :is-loading="isUploadingPhoto"
            :photo="form.avatar"
            :vuelidate="$v.form.avatar"
            @change="onChangePhoto"
            @remove="onDeletePhoto"
          />
        </b-col>
      </b-row>
      <InputString
        class="staff-name"
        :label="$t('label.name')"
        size="lg"
        v-model="$v.form.name.$model"
        :vuelidate="$v.form.name"
      />
      <InputString
        class="staff-last-name"
        :label="$t('label.lastName')"
        size="lg"
        v-model="$v.form.lastName.$model"
        :vuelidate="$v.form.lastName"
      />
      <InputString
        class="staff-email"
        :label="$t('label.email')"
        size="lg"
        v-model="$v.form.email.$model"
        :vuelidate="$v.form.email"
      />
      <InputPassword
        class="staff-password"
        :label="$t('label.password')"
        size="lg"
        v-model="$v.form.password.$model"
        :vuelidate="$v.form.password"
        :invalidFeedback="$t('validation.passwordInvalidFeedback')"
      />
      <InputSelect
        class="staff-type"
        :label="$t('label.type')"
        size="lg"
        v-model="$v.form.type.$model"
        :vuelidate="$v.form.type"
        :options="typeList"
        value-field="value"
      />
    </b-form>
  </ModalMain>
</template>

<script>
import { isNil } from 'lodash';
import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import { email, required, helpers } from 'vuelidate/lib/validators';
import { STAFF_CREATE, STAFF_UPDATE } from '@/store/actions/staff';
import { FILE_UPLOAD } from '@/store/actions/file';
import passwordRegExp from '@/utils/password';
import editModalMixin from '@/mixins/editModalMixin';
import ModalMain from '@/components/ui/ModalMain';
import InputPhoto from '@/components/ui/input/InputPhoto';
import InputLabel from '@/components/ui/input/InputLabel';
import InputString from '@/components/ui/input/InputString';
import InputPassword from '@/components/ui/input/InputPassword';
import InputSelect from '@/components/ui/input/InputSelect';

const defaultValuesForForm = () => ({
  name: '',
  lastName: '',
  email: '',
  avatar: null,
  password: '',
  type: '',
});

export default {
  mixins: [validationMixin, editModalMixin],

  props: {
    formData: Object,
    typeList: Array,
  },

  data() {
    return {
      form: defaultValuesForForm(),
    };
  },

  validations() {
    if (isNil(this.formData)) {
      return {
        form: {
          name: {
            required,
          },
          lastName: {
            required,
          },
          email: {
            required,
            email,
          },
          avatar: {
            required,
          },
          type: {
            required,
          },
          password: {
            required,
            password: helpers.regex('password', passwordRegExp),
          },
        },
      };
    }
    return {
      form: {
        name: {
          required,
        },
        lastName: {
          required,
        },
        email: {
          required,
          email,
        },
        avatar: {
          required,
        },
        type: {
          required,
        },
        password: {
          password: helpers.regex('password', passwordRegExp),
        },
      },
    };
  },

  watch: {
    formData() {
      this.updateForm();
    },
  },

  computed: {
    ...mapGetters(['staffStatus', 'getFileUpload']),
    isLoading() {
      return this.staffStatus === 'loading';
    },
    title() {
      return isNil(this.formData) ? this.$t('staff.newStaff') : this.$t('staff.editStaff');
    },
    saveButtonTitle() {
      return isNil(this.formData) ? this.$t('staff.addStaff') : this.$t('staff.updateStaff');
    },
    isUploadingPhoto() {
      return this.getFileUpload.status === 'loading';
    },
  },

  methods: {
    updateForm() {
      this.form = this.formData
        ? {
            name: this.formData.name,
            lastName: this.formData.lastName,
            email: this.formData.email,
            avatar: this.formData.avatar,
            password: '',
            type: this.formData.type,
          }
        : defaultValuesForForm();
      this.$v.$reset();
    },
    resetForm() {
      this.form = defaultValuesForForm();
      this.$v.$reset();
    },
    onHidden() {
      this.$emit('onHidden');
      this.resetForm();
    },
    onCancel() {
      this.hideEditModal();
    },
    async onSave() {
      if (this.$v) {
        this.$v.form.$touch();
        if (this.$v.form.$anyError) {
          return;
        }
      }

      const data = this.form;

      if (isNil(this.formData)) {
        await this.$store.dispatch(STAFF_CREATE, data);
      } else {
        const payload = {
          id: this.formData.id,
          data: {
            ...data,
            password: this.form.password || null,
          },
        };
        await this.$store.dispatch(STAFF_UPDATE, payload);
      }
      if (this.staffStatus === 'success') {
        this.hideEditModal();
      }
    },
    async onChangePhoto(e) {
      const file = e.target.files[0];
      await this.$store.dispatch(FILE_UPLOAD, file);
      if (this.getFileUpload.status === 'success') {
        this.form.avatar = this.getFileUpload.item ? this.getFileUpload.item : null;
      }
    },
    async onDeletePhoto() {
      // await this.$store.dispatch(FILE_DELETE, this.form.avatar.id);
      // if (this.fileDeleteStatus === 'success') {
      this.form.avatar = null;
      // }
    },
  },

  components: {
    ModalMain,
    InputPhoto,
    InputLabel,
    InputString,
    InputPassword,
    InputSelect,
  },
};
</script>
