<template>
  <b-form-group
    :label="label"
    :label-class="labelClass"
    :label-for="`input-${uuid}`"
    :invalid-feedback="invalidFeedback"
    :valid-feedback="validFeedback"
    :state="$_state"
    :disabled="disabled"
  >
    <vue-editor
      :id="isLarge ? 'input-editor-large' : ''"
      :class="[`input-editor ${size ? `input-editor-${size}` : ''}`, { 'has-error': $_hasError }]"
      :value="value"
      :editorToolbar="customToolbar"
      :customModules="customModulesForEditor"
      :editorOptions="editorSettings"
      useCustomImageHandler
      @image-added="handleImageAdded"
      @input="onInput"
    ></vue-editor>
  </b-form-group>
</template>

<script>
import baseInputMixin from '@/mixins/baseInputMixin';
import { mapGetters } from 'vuex';
import { FILE_UPLOAD } from '@/store/actions/file';
import { VueEditor } from 'vue2-editor';
import ImageResize from 'quill-image-resize-vue';

export default {
  mixins: [baseInputMixin],

  props: {
    isLarge: {
      type: Boolean,
      default: false,
    },
    hasImage: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      customModulesForEditor: [{ alias: 'imageResize', module: ImageResize }],
      editorSettings: {
        modules: {
          imageResize: {},
        },
      },
    };
  },

  computed: {
    ...mapGetters(['getFileUpload']),
    customToolbar() {
      let result = [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
        ['blockquote'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ color: [] }, { background: [] }],
        ['link', 'clean'],
      ];
      if (this.hasImage) {
        result = [...result, ['image']];
      }
      return result;
    },
  },

  methods: {
    async handleImageAdded(file, Editor, cursorLocation, resetUploader) {
      await this.$store.dispatch(FILE_UPLOAD, file);
      if (this.getFileUpload.status === 'success') {
        const image = this.getFileUpload.item ? this.getFileUpload.item : null;
        if (image && image.path) {
          Editor.insertEmbed(cursorLocation, 'image', image.path);
          resetUploader();
        }
      }
    },
  },

  components: {
    VueEditor,
  },
};
</script>

<style lang="scss" scoped>
@import '../../../sass/variables';

::v-deep {
  .input-editor {
    .ql-toolbar {
      padding: 0.5rem 0.75rem !important;
      border: 2px solid $border-color;
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;

      .ql-formats {
        margin-bottom: 0 !important;
      }

      button {
        svg {
          width: 1rem !important;
          height: 1rem !important;
        }
      }
    }

    .ql-container {
      font-family: $font-family-base;
      border-width: 2px;
      border-color: $border-color;
      border-bottom-left-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
    }

    .ql-editor {
      height: 120px;
      color: $gray-700;
      padding: $input-btn-padding-y $input-btn-padding-x;
    }

    &.input-editor-lg {
      .ql-toolbar {
        border-top-left-radius: $border-radius-lg;
        border-top-right-radius: $border-radius-lg;
      }

      .ql-container {
        border-bottom-left-radius: $border-radius-lg;
        border-bottom-right-radius: $border-radius-lg;
      }

      .ql-editor {
        padding: $input-btn-padding-y-lg $input-btn-padding-x-lg;
      }
    }

    &.input-editor-sm {
      .ql-toolbar {
        border-top-left-radius: $border-radius-sm;
        border-top-right-radius: $border-radius-sm;
      }

      .ql-container {
        border-bottom-left-radius: $border-radius-sm;
        border-bottom-right-radius: $border-radius-sm;
      }

      .ql-editor {
        padding: $input-btn-padding-y-sm $input-btn-padding-x-sm;
      }
    }
  }

  &.is-invalid {
    .input-editor {
      .ql-toolbar {
        border-color: $danger;
        border-bottom-color: $border-color;
      }

      .ql-container {
        border-color: $danger;
      }
    }
  }

  #input-editor-large {
    height: 350px;

    .ql-editor {
      height: 100%;
    }
  }

  .ql-editor {
    line-height: 1.5;

    p {
      margin-top: 0;
      margin-bottom: 1rem;
    }
  }

  // &.is-valid {
  //   .input-editor {
  //     .ql-toolbar {
  //       border-color: $success;
  //       border-bottom-color: $border-color;
  //     }

  //     .ql-container {
  //       border-color: $success;
  //     }
  //   }
  // }
}
</style>
