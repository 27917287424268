<template>
  <BaseLayout :title="title">
    <template #actions>
      <InlineButton @click="onCreate" pill shadow>
        <span class="text-uppercase">{{ $t('text.newButton') }}</span>
      </InlineButton>
    </template>

    <NewsTable
      :list="newsList"
      :meta="newsListMeta"
      :current-page="currentPage"
      :is-loading="isLoading"
      @edit="onEdit"
      @remove="onConfirmDelete"
      @onChangePage="onChangePage"
    />

    <ConfirmDialog :id="deleteModalId" @ok="remove" />
  </BaseLayout>
</template>

<script>
import { mapGetters } from 'vuex';
import { NEWS_LIST_REQUEST, NEWS_DELETE } from '@/store/actions/news';
import BaseLayout from '@/components/ui/BaseLayout';
import InlineButton from '@/components/ui/button/InlineButton';
import ConfirmDialog from '@/components/ui/ConfirmDialog';
import NewsTable from '@/components/manage/news/NewsTable';

export default {
  props: {
    title: String,
  },

  data() {
    return {
      currentPage: 1,
      dataIdToRemove: null,
      deleteModalId: 'manage-delete-modal',
    };
  },

  computed: {
    ...mapGetters(['newsStatus', 'newsList', 'newsListMeta', 'newsListStatus', 'news']),
    isLoading() {
      return this.newsListStatus === 'loading';
    },
  },

  created() {
    this.fetchData();
  },

  methods: {
    async fetchData() {
      const payload = {
        limit: 15,
        page: this.currentPage,
        filter: {},
      };
      await this.$store.dispatch(NEWS_LIST_REQUEST, payload);
    },
    onCreate() {
      this.$router.push({ name: 'NewsAdd' });
    },
    onEdit(id) {
      this.$router.push({ name: 'NewsEdit', params: { id } });
    },
    onChangePage(page) {
      this.currentPage = page;
      this.fetchData();
    },
    onConfirmDelete(id) {
      this.dataIdToRemove = id;
      this.$bvModal.show(this.deleteModalId);
    },
    async remove() {
      await this.$store.dispatch(NEWS_DELETE, this.dataIdToRemove);
      if (this.newsStatus === 'deleted') {
        this.dataIdToRemove = null;
        this.fetchData();
      }
    },
  },

  components: {
    BaseLayout,
    InlineButton,
    NewsTable,
    ConfirmDialog,
  },
};
</script>
